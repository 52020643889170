<template>
  <div class="plan-list">
    <slot name="default">
      <div class="plan-card" :class="{'plan-card_best': p.isBest}" v-for="p in plans" :key="p.id">
        <div v-if="p.canBuy && (p.discount || discount)" class="plan-card__profit">Экономия {{p.discount + discount}}%</div>
        <div class="plan-card__title">{{ p.title }}</div>
        <Price class="plan-card__price" :price="Math.ceil(p.price * period * discountFactor)" />
        <div>
          <Price class="plan-card__month-price" :price="Math.ceil(p.price * discountFactor)" v-if="p.canBuy && period > 1" />
          в месяц*
        </div>
        <Separator />
        <div class="plan-card__futures">
          <div><Price :price="p.unitsLimit" sign="go"/>**</div>
          <div v-if="p.requestsLimit">{{p.requestsLimit}}&thinsp;запросов в день</div>
          <div>Доступ к GPT-4 Omni Mini<template v-if="p.supportsAdvancedChatModels">, GPT-4o 1, GPT-4o 3 Mini</template></div>
          <div>Доступ к Claude Haiku<template v-if="p.supportsAdvancedChatModels">, Sonnet, Opus</template></div>
          <div>Доступ к DeepSeek V3<template v-if="p.supportsAdvancedChatModels">, R1</template></div>
          <div v-if="p.supportsAdvancedChatModels">Доступ к Qwen 2.5 72B</div>
          <div v-if="p.supportsAdvancedImagingModels">Доступ к Midjourney 6.1, Dall-E 3 и Flux 1.1 Pro Ultra</div>
          <div v-else>Доступ к Midjourney 5.2, Flux 1 Dev</div>
          <div v-if="p.supportsVision">Анализ изображений</div>
          <div v-if="p.supportsVision">Анализ YouTube-видео</div>
          <div v-if="p.supportsVision">Обработка ссылок</div>
          <div v-if="p.supportsVision">Обработка текстовых файлов (PDF, Word и др.)</div>
          <Notice severity="error" v-if="!p.canBuy">
            Необходима активная подписка на Telegram-канал @GoGptRu
          </Notice>
        </div>
        <Button @click="$emit('select', p)" v-if="p.canBuy" icon="card" class="mt-auto">Получить</Button>
        <Button v-else-if="p.isDefault"
                icon="telegram"
                class="mt-auto"
                href="https://t.me/GoGptRu"
                target="_blank"
                @click="subscribe()">Подписаться</Button>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import api from '@/utils/api';
import Separator from '@/components/Separator.vue';
import Button from '@/components/Button.vue';
import Price from '@/components/Price.vue';
import Notice from '@/components/Notice.vue';

const props = defineProps({
  plans: Array,
  period: { type: Number, default: 1 },
  discount: { type: Number, default: 0 },
});

defineEmits(['select']);

const discountFactor = computed(() => (100 - props.discount) / 100);

const subscribe = async () => {
  await api.post('account/subscribe');
};
</script>

<style lang="scss">
.plan-list{
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  .plan-card{
    flex-basis: 200px;
  }
}
</style>
